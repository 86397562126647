.actionSheetController__orderStatuses {
    .action-sheet-title {
        color: var(--color-black);
        text-align: center;
        border-bottom: 1px solid var(--color-passive);
        padding-bottom: 1rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    .action-sheet-sub-title {
        font-weight: normal;
        color: var(--color-passive);
        padding-top: 0.1rem;
    }

    .action-sheet-icon {
        padding-bottom: 0;
    }

    .rejected {
        color: red !important;

        svg {
            fill: red !important;
        }

        .action-sheet-icon {
            color: red !important;
        }
    }

    .changeStatus__button {
        color: var(--color-black);
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.15px;
    }

    .backButton {
        border-top: 1px solid var(--color-passive);
        padding-top: 0.1rem;
    }

    .action-sheet-group {
        border-radius: 15px;
        margin: 2%;
    }
}

.on_the_way_danger_toast {
    font-size: 16px;
}
