:root {
    --color-primary: #0060FF;
    --color-contrast: #FFF;
    --color-passive: #99A2AD;
    --color-light: #F5F7FB;
    --color-black: #3A3A3A;
    --color-main: #f0f2f6;
    --color-error: #FF3A47;
    --color-border: #D5D6D8;
    --color-active: #818C99
}
