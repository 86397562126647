ion-popover {
    // --background: rgba(40, 173, 218, 0.6);
    --backdrop-opacity: 0;
    --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
    --color: white;
    top: 0.5rem;
    left: -10px;
    // --width: 300px;
}

ion-popover ion-content {
    // --background: rgba(40, 173, 218, 0.6);
    ion-list {
        ion-item {
            --border-color: transparent;
            ion-icon {
                margin-right: 1rem;
            }
        }
    }
}

ion-popover::part(backdrop) {
    // background-color: rgb(6, 14, 106);
}
